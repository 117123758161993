import React from "react";
import "./markdownBox.scss";

const BeforeAfter = ({ children, bordered, size, label }) => {
	return (
		<div
			className={`markdownBox ${bordered && "bordered"} ${
				label && "labelPresent"
			} ${size && size}`}
		>
			{label && <div className="markdownBoxLabel">{label}</div>}
			{children}
		</div>
	);
};

export default BeforeAfter;
