export const gradients = {
	seeMode: `linear-gradient(
    45deg,
    hsl(148deg 72% 89%) 0%,
    hsl(148deg 61% 84%) 10%,
    hsl(149deg 54% 80%) 20%,
    hsl(149deg 50% 76%) 30%,
    hsl(149deg 48% 71%) 40%,
    hsl(150deg 46% 67%) 50%,
    hsl(148deg 53% 69%) 60%,
    hsl(145deg 62% 72%) 70%,
    hsl(142deg 72% 74%) 80%,
    hsl(139deg 84% 77%) 90%,
    hsl(135deg 100% 80%) 100%
          )`,
	eCommerce: `linear-gradient(  45deg,
            hsl(334deg 74% 74%) 0%,
            hsl(339deg 83% 74%) 10%,
            hsl(345deg 90% 75%) 20%,
            hsl(350deg 95% 75%) 30%,
            hsl(355deg 99% 75%) 40%,
            hsl(0deg 100% 75%) 50%,
            hsl(0deg 100% 72%) 60%,
            hsl(1deg 100% 70%) 70%,
            hsl(1deg 100% 67%) 80%,
            hsl(1deg 100% 65%) 90%,
            hsl(0deg 100% 62%) 100%)`,
	designSystem: `linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)`,
};
