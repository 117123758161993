/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Target, HeartPulse, Mic, Gem, HeartHandshake, Component, Code, Video, Lightbulb} from "lucide-react";
import customer from "./images/customer.jpg";
import before from "./images/before.jpg";
import after from "./images/after.jpg";
import people from "./images/people.jpg";
import marketing from "./images/marketing.jpg";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    span: "span",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {MarkdownBox, InfoGrid, InfoGridItem, Stack, StackItem} = _components;
  if (!InfoGrid) _missingMdxReference("InfoGrid", true);
  if (!InfoGridItem) _missingMdxReference("InfoGridItem", true);
  if (!MarkdownBox) _missingMdxReference("MarkdownBox", true);
  if (!Stack) _missingMdxReference("Stack", true);
  if (!StackItem) _missingMdxReference("StackItem", true);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "callout"
  }, React.createElement("div", {
    className: "calloutIcon"
  }, React.createElement(Lightbulb, {
    size: "32"
  })), React.createElement("div", {
    className: "calloutBody"
  }, React.createElement(_components.p, null, "This is a high-level overview of my areas of responsibilities. Please reach\nout for a more in-depth walk through of specific challenges and solutions."))), "\n", React.createElement(_components.h2, {
    id: "context"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#context"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "CONTEXT"), "\n", React.createElement(_components.p, null, "See-Mode is a relatively young Series-A funded startup with 25 employees that has developed the world's first AI software for analysis and reporting of vascular ultrasound. The product has distribution across USA, Australia, New Zealand and Singapore."), "\n", React.createElement(_components.h3, {
    id: "my-role"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#my-role"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "MY ROLE"), "\n", React.createElement(_components.p, null, "As Head of Design I was tasked with establishing the right design, product and team foundations to set up the company for success as well as executing and delivering on product and design initiatives."), "\n", React.createElement(MarkdownBox, {
    bordered: true,
    size: "medium",
    label: "HIGHLIGHTS"
  }, React.createElement(InfoGrid, null, React.createElement(InfoGridItem, {
    title: "Leadership",
    icon: React.createElement(Target, {
      size: "32"
    }),
    caption: "Nurturing a now-next-later mindset to help the company with focus."
  }), React.createElement(InfoGridItem, {
    title: "Design",
    icon: React.createElement(Gem, {
      size: "32"
    }),
    caption: "Evolving the product for use in clinical settings within hospitals."
  }), React.createElement(InfoGridItem, {
    title: "Product",
    icon: React.createElement(HeartPulse, {
      size: "32"
    }),
    caption: "Taking on the additional role of Product Manager for eight months."
  }), React.createElement(InfoGridItem, {
    title: "Hiring",
    icon: React.createElement(Mic, {
      size: "32"
    }),
    caption: "Hiring our first Head of Product and second Product Designer."
  }), React.createElement(InfoGridItem, {
    title: "People",
    icon: React.createElement(HeartHandshake, {
      size: "32"
    }),
    caption: "Mentoring and organising activities for growth and engagement."
  }), React.createElement(InfoGridItem, {
    title: "Design System",
    icon: React.createElement(Component, {
      size: "32"
    }),
    caption: "Delivering a new visual language and react component library."
  }))), "\n", React.createElement(_components.h2, {
    id: "customer"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#customer"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "CUSTOMER"), "\n", React.createElement(_components.p, null, "The main user of the See-Mode product is a sonographer. They specialise in ultrasound and produce reports and diagnostic images across a range of examinations by operating ultrasound machines. Their reports are used by doctors to inform the diagnosis and best patient care."), "\n", React.createElement(_components.p, null, "This is where See-Mode fits in: with automated reports for time saving, consistency and AI-enhanced diagnostics for the best possible patient outcomes."), "\n", React.createElement("img", {
    className: "markdownImage",
    src: customer
  }), "\n", React.createElement(_components.h2, {
    id: "design"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#design"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "DESIGN"), "\n", React.createElement(_components.p, null, "I led the product team through evolving the product from an early proof of concept to a production-ready system for use within clinics around the world."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Performed generative research to uncover new opportunities"), "\n", React.createElement(_components.li, null, "Validated ideas and clinical requirements with clinicians"), "\n", React.createElement(_components.li, null, "Created a design system to help with consistency and efficiency of our product and our work"), "\n"), "\n", React.createElement(MarkdownBox, {
    size: "medium"
  }, React.createElement(Stack, null, React.createElement(StackItem, {
    variant: "desktop",
    image: before,
    caption: "Before"
  }), React.createElement(StackItem, {
    variant: "desktop",
    image: after,
    caption: "After building out our new design system"
  }))), "\n", React.createElement(_components.h2, {
    id: "product"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#product"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "PRODUCT"), "\n", React.createElement(_components.p, null, "For eight months, during the search of our first-ever Head of Product, I took on the product manager role in addition to my other responsibilities. I identified our most impactful opportunities and handled things like:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Quarterly and weekly planning and prioritisation"), "\n", React.createElement(_components.li, null, "Writing product and design briefs"), "\n", React.createElement(_components.li, null, "Leading team sprints"), "\n", React.createElement(_components.li, null, "Onboarding new customers"), "\n"), "\n", React.createElement(_components.h2, {
    id: "people"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#people"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "PEOPLE"), "\n", React.createElement(_components.p, null, "I was the hiring manager for the roles of Head of Product and Senior Product Designer, managed a Senior Product Designer and mentored a Junior Engineer. As part of hiring I also designed new hiring processes, take-home challenges and onboarding documents."), "\n", React.createElement(_components.p, null, "I also introduced new processes for the team to have more autonomy and feel empowered to take initiative and identify opporunities to improve the product. I organised field trips, provided resources to assist with understanding our clinincal work, shared insights, goals and work-in-progress, and helped establish company-wide policies for a more inclusive and remote-friendly workplace."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Established new hiring processes"), "\n", React.createElement(_components.li, null, "Facilitated collaborative product and design kick-offs"), "\n", React.createElement(_components.li, null, "Mentored and coached engineers and designers"), "\n", React.createElement(_components.li, null, "Introduced and helped define company-wide policies regarding visas, work-from-home allowances and reimbursements for work-related purchases"), "\n"), "\n", React.createElement("img", {
    className: "markdownImage",
    src: people
  }), "\n", React.createElement(_components.h2, {
    id: "engineering"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#engineering"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "ENGINEERING"), "\n", React.createElement(_components.p, null, "When I joined See-Mode we didn't have a dedicated Senior Front-End Engineer. So I took on this role and made key decisions around the direction of our new platform."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Built a static version of the platform in React as the foundation for our re-platforming"), "\n", React.createElement(_components.li, null, "Invited guest speakers for talks and Q&A on re-platforming and front-end architecture"), "\n", React.createElement(_components.li, null, "Facilitated technical integration discussions with hospitals"), "\n", React.createElement(_components.li, null, "Performed code reviews"), "\n"), "\n", React.createElement(_components.h2, {
    id: "marketing"
  }, React.createElement(_components.a, {
    "aria-hidden": "true",
    tabIndex: "-1",
    href: "#marketing"
  }, React.createElement(_components.span, {
    className: "icon icon-link"
  })), "MARKETING"), "\n", React.createElement(_components.p, null, "I delivered on a few lean experiments to see if we could improve acquisition through different product and brand messaging. I also wrote the script for and helped direct a short promotional video to be used as marketing collateral."), "\n", React.createElement("img", {
    className: "markdownImage",
    src: marketing
  }), "\n", React.createElement("div", {
    className: "iframe"
  }, React.createElement("iframe", {
    src: "https://www.youtube.com/embed/lgUl1PfZpK0",
    title: "YouTube video player",
    frameBorder: "0",
    allowFullScreen: true
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
