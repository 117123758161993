import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import "./spot.scss";

const spotAnimations = {
	initial: {
		zIndex: 2,
		left: 0,
		top: 0,
		right: 0,
	},
	maximized: {
		zIndex: 4,
		left: 0,
		top: 0,
		right: 0,
	},
};

const Spot = ({
	title,
	video,
	bgGradient,
	ratio = "landscape",
	theme,
	description,
	labels = [],
	logo,
	position,
	isMaximized,
	to,
}) => {
	const wrapperRef = useRef(null);
	const videoRef = useRef(null);
	const [spotHeight, setSpotHeight] = useState(0);

	return (
		<motion.div
			className={`spot ${
				position === "left" ? "left" : position === "first" ? "first" : ""
			}`}
			data-theme={theme}
		>
			<div className={`spotWrapperUnderlay ${isMaximized && "show"}`} />
			<div className="spotLogoContainer">
				<div className="spotCorner" />
				<div className="spotLogo">
					<img
						src={logo}
						height="32"
						style={{ display: "block" }}
						alt={`${to}`}
					/>
				</div>
			</div>

			<Link
				to={to}
				className="spotLink"
				activeStyle={{ height: spotHeight + "px" }}
				ref={wrapperRef}
				onClick={(e) => setSpotHeight(e.currentTarget.clientHeight)}
			>
				<div className="spotHighlight" />
				<motion.div
					className={`spotWrapper ${isMaximized && `maximized`}`}
					variants={spotAnimations}
					initial="initial"
					animate={isMaximized ? "maximized" : "initial"}
					layout
				>
					<motion.div
						style={{
							borderRadius: "var(--spot-borderRadius)",
							backgroundImage: bgGradient,
						}}
						className={`${ratio} spotHeader`}
						layout
					>
						<motion.div layout="position" className="spotInfo">
							<motion.div layout="position" className="spotInfoInner">
								<div className="spotTitleContainer">
									<h2 className="display spotTitle">{title}</h2>
								</div>
								<p className="spotDescription">{description}</p>
								<div className="labels">
									{labels.map((label) => (
										<div key={label} className="label">
											{label}
										</div>
									))}
								</div>
							</motion.div>
						</motion.div>
						<motion.div className="spotVideoWrapper">
							<motion.div layout className={`${ratio} spotVideoContainer`}>
								{/* <motion.div className="videoHighlight" /> */}
								<motion.div layout className="spotVideoFrame">
									<motion.div layout className="spotVideoHeader" />
									<motion.video
										className="spotVideo"
										muted
										ref={videoRef}
										loop
										playsInline
										autoPlay
										layout
									>
										<motion.source layout src={video} type="video/mp4" />
									</motion.video>
								</motion.div>
							</motion.div>
						</motion.div>
					</motion.div>
				</motion.div>
			</Link>
		</motion.div>
	);
};

export default Spot;
