import React from "react";
import "./infoGrid.scss";

const InfoGrid = ({ children, maxWidth }) => {
	return (
		<div className="blockCards" style={{ maxWidth: maxWidth }}>
			{children}
		</div>
	);
};

export const InfoGridItem = ({ icon, caption, title }) => {
	return (
		<div className="blockCard">
			<div className="blockCardIconDesktop">{icon}</div>
			<div className="blockCardBody">
				<h3 className="blockCardTitle">
					<div className="blockCardIconMobile">{icon}</div>
					{title}
				</h3>
				<p>{caption}</p>
			</div>
		</div>
	);
};

export default InfoGrid;
