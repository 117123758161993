import React from "react";
import { motion } from "framer-motion";
import "./weather.scss";

const container = {
	show: {
		transition: {
			staggerChildren: 0.2,
			type: "spring",
		},
	},
};

const item = {
	hidden: { opacity: 0, y: -200 },
	show: {
		opacity: 1,
		y: 0,
		transition: {
			type: "spring",
			stiffness: 100,
		},
	},
};

const sunContainer = {
	show: {
		transition: {
			staggerChildren: 0,
			duration: 1,
		},
	},
};

const sunItem = {
	hidden: {
		y: -300,
		opacity: 0,
	},
	show: {
		y: 0,
		opacity: 1,
		transition: {
			type: "spring",
			stiffness: 100,
		},
	},
};

const Weather = ({ isDark }) => {
	return (
		<div className="weather">
			<motion.div
				className="night"
				variants={container}
				initial={isDark ? "hidden" : false}
				animate={isDark ? "show" : "hidden"}
			>
				<motion.div variants={item}>
					<div className="clouds" />
				</motion.div>
				<motion.div variants={item}>
					<div className="moon" />
				</motion.div>
				<motion.div variants={item}>
					<div className="stars-a" />
				</motion.div>
				<motion.div variants={item}>
					<div className="stars-b" />
				</motion.div>
				<motion.div variants={item}>
					<div className="stars-c" />
				</motion.div>
			</motion.div>
			<motion.div
				className="day"
				variants={sunContainer}
				initial={isDark ? false : "hidden"}
				animate={isDark ? "hidden" : "show"}
			>
				<motion.div className="sunContainer" variants={sunItem}>
					<div className="sun">
						<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
							<path
								fill="#ffcf84"
								d="M49.5,-68.1C64.3,-57.5,76.5,-43.1,82.9,-26.3C89.3,-9.4,90,10,84.5,27.6C79,45.2,67.4,61,52.3,72.6C37.2,84.2,18.6,91.7,0.2,91.4C-18.2,91.1,-36.3,83,-51.5,71.4C-66.7,59.9,-79,44.7,-84.3,27.6C-89.6,10.4,-88,-8.8,-81.7,-26.1C-75.5,-43.3,-64.5,-58.5,-50.1,-69.3C-35.7,-80.1,-17.9,-86.4,-0.2,-86C17.4,-85.7,34.8,-78.8,49.5,-68.1Z"
								transform="translate(100 100)"
							/>
						</svg>
					</div>
					<div className="rays">
						{new Array(30).fill("").map((x, i) => (
							<div
								className="rayContainer"
								style={{ transform: `rotate(${i * 12}deg)` }}
								key={i}
							>
								<svg
									className="ray"
									width="17"
									height="64"
									viewBox="0 0 17 64"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M15.5 1C-17 19.5 15.5 36 15.5 62.5"
										stroke="#ffcf84"
										strokeWidth="8"
										strokeLinecap="round"
									/>
								</svg>
							</div>
						))}
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default Weather;
