import React from "react";
import {
	Home,
	Briefcase,
	Wrench,
	Globe2,
	Bot,
	Code2,
	HeartHandshake,
	MousePointerClick,
	PersonStanding,
	Rocket,
	Linkedin,
	Github,
	Component,
	Mail,
	Copy,
	ExternalLink,
} from "lucide-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Spot from "../components/Spot/spot.js";
import { gradients } from "../utils/gradients.js";
import Heading from "../components/Heading/heading.js";
import MarkdownBox from "../components/MarkdownBox/markdownBox.js";
import List, { ListItem, ListSeparator } from "../components/List/list.js";
import Weather from "../components/Weather/weather.js";
import GradientText from "../components/GradientText/gradientText.js";
// import SpotExternal from "../components/SpotExternal/spotExternal.js";
import SeemodeContent from "../content/seemode.mdx";
import RbppContent from "../content/rbpp.mdx";
import RbdsContent from "../content/rbds.mdx";
import rbdsVideo from "../assets/rbds.mp4";
import seemodeVideo from "../assets/seemode.mp4";
import rbppVideo from "../assets/rbpp.mp4";
import smlogo from "../assets/seemode_logo.svg";
import rblogo from "../assets/RB_White_RGB.svg";
import InfoGrid, { InfoGridItem } from "../components/InfoGrid/infoGrid.js";
import { AnimatePresence } from "framer-motion";

// const isBrowser = typeof window !== "undefined";

const Layout = ({ children, location }) => {
	// const [isDark, setDark] = React.useState(
	// 	isBrowser
	// 		? window.matchMedia("(prefers-color-scheme: dark)").matches
	// 		: false
	// );

	const [copiedEmail, setCopiedEmail] = React.useState(false);
	const [emailHover, setEmailHover] = React.useState(false);
	const [linkedInHover, setLinkedInHover] = React.useState(false);
	const [gitHubHover, setGitHubHover] = React.useState(false);

	React.useEffect(() => {
		const timeout = setTimeout(() => setCopiedEmail(false), 3000);

		// this will clear Timeout
		// when component unmount like in willComponentUnmount
		// and show will not change to true
		return () => {
			clearTimeout(timeout);
		};
	}, [copiedEmail]);

	return (
		<main>
			<div className="main">
				{/* <Background isDark={isDark} /> */}
				<Weather isDark={true} />
				<div className="block">
					<div className="hero">
						<h1 className="display name text">
							<div>Marcus</div>
							<div>Hammarstedt</div>
						</h1>
					</div>
				</div>
				<div className="blockConnectorContainer aboutBlock">
					<div className="blockConnector">
						<div className="blockConnectorIndicator">
							<div className="blockConnectorIcon">
								<Bot size={32} />
							</div>
						</div>
					</div>
					<div className="blockConnectorDescription">
						<Heading>TLDR</Heading>
						<div className="blockConnectorCaption">
							<GradientText>
								I'm a highly adaptable designer with a passion for crafting
								intuitive and impactful products
							</GradientText>
						</div>
						<p className="blockConnectorSubCaption">
							I use code, design and collaboration to produce the highest
							quality work that delivers business value and makes my team proud
						</p>
					</div>
					<div className="introBlock">
						<div className="introCorner" />
						<div className="introHorizontal" />
						<div className="intro">
							<div>
								<Heading>ROLES</Heading>
								<List>
									<ListItem
										indicator="2020"
										link="https://www.linkedin.com/company/see-mode-technologies/"
										linkTitle="@See-Mode"
									>
										Head of Design
									</ListItem>
									<ListItem
										indicator="2018"
										link="https://www.linkedin.com/company/redbubble/"
										linkTitle="@Redbubble"
									>
										Lead Design Technologist
									</ListItem>
									<ListItem
										indicator="2016"
										link="https://www.linkedin.com/company/redbubble/"
										linkTitle="@Redbubble"
									>
										Senior Product Designer
									</ListItem>
									<ListItem
										indicator="2014"
										link="https://www.linkedin.com/company/intershop-communications/"
										linkTitle="@Intershop"
									>
										Senior UI Developer
									</ListItem>
								</List>
							</div>
							<div>
								<Heading>AT A GLANCE</Heading>
								<List>
									<ListItem indicator={<Wrench />}>
										UI
										<ListSeparator />
										UX
										<ListSeparator />
										HTML
										<ListSeparator />
										CSS
										<ListSeparator />
										Javascript
									</ListItem>
									<ListItem indicator={<Briefcase />}>
										Remote
										<ListSeparator />
										Hybrid
									</ListItem>
									<ListItem indicator={<Home />}>Santa Cruz, CA</ListItem>
									<ListItem indicator={<Globe2 />}>
										USA <ListSeparator />
										<span className="muted">Australia</span>
										<ListSeparator />
										<span className="muted">Sweden</span>
									</ListItem>
								</List>
							</div>
						</div>
					</div>
				</div>
				<div className="block skillHighlights">
					<MarkdownBox size="fluid" bordered label="CORE SKILLS">
						<InfoGrid>
							<InfoGridItem
								icon={<MousePointerClick size="32" />}
								title="UI"
								caption="Designing and building intuitive and accessible interfaces for
										complex use cases such as medical diagnostics."
							/>
							<InfoGridItem
								icon={<PersonStanding size="32" />}
								caption="Designing, preparing and conducting qualitative and
										quantitative research to inform decision making."
								title="UX"
							/>
							<InfoGridItem
								icon={<Code2 size="32" />}
								title="Dev"
								caption="Deploying performant and accessible code to production environments through Git workflows."
							/>
							<InfoGridItem
								icon={<Component size="32" />}
								title="Design Systems"
								caption="Creating, maintaining and educating on design
										systems for small and large teams."
							/>
							<InfoGridItem
								icon={<HeartHandshake size="32" />}
								title="People"
								caption="Defining and running inclusive hiring processes. Onboarding,
										mentoring and coaching others."
							/>
							<InfoGridItem
								icon={<Rocket size="32" />}
								title="Team"
								caption="Planning and leading sprints. Introducing new team processes
										and company policies."
							/>
						</InfoGrid>
					</MarkdownBox>
				</div>
				<div className="blockConnectorContainer">
					<div className="blockConnector">
						<div className="blockConnectorIndicator">
							<div className="blockConnectorIcon">
								<Briefcase size={32} />
							</div>
						</div>
					</div>
					<div className="blockConnectorDescription">
						<Heading>EXPERIENCE</Heading>
						<GradientText className="blockConnectorCaption">
							I've taken new products to market, led product teams, built and
							educated on design systems, spearheaded accessibility initiatives
							and delivered solutions quickly by being pragmatic and applying
							lean and agile methodologies
						</GradientText>
					</div>
				</div>
				<div className="block spotBlock">
					<Spot
						title="See-Mode"
						subtitle="Startup"
						video={seemodeVideo}
						theme="seemode"
						description="Bringing a new medical technology product that leverages computer vision and AI to the global market as quickly as possible."
						bgGradient={gradients.seeMode}
						labels={["2020 – 2023", "Startup"]}
						markdown={<SeemodeContent />}
						logo={smlogo}
						isMaximized={location.pathname === "/see-mode/"}
						position="first"
						to="/see-mode"
						customTheme="seemode"
					/>
					<Spot
						title="Redbubble"
						subtitle="Design System"
						video={rbdsVideo}
						bgGradient={gradients.designSystem}
						theme="rbds"
						description="Establishing and maintaining a design system to unite teams around a shared visual language and empower them to create scalable, high-quality products quickly."
						labels={["2018 – 2020", "Design System"]}
						markdown={<RbdsContent />}
						logo={rblogo}
						isMaximized={location.pathname === "/rb-design-system/"}
						position="left"
						to="/rb-design-system"
						customTheme="rbds"
					/>
					<Spot
						title="Redbubble"
						subtitle="Product Page"
						video={rbppVideo}
						ratio="portrait"
						description="Re-imagining the mobile product page experience as part of a large re-platforming initiative with a focus on performance and scalability."
						bgGradient={gradients.eCommerce}
						theme="rbpp"
						labels={["2016 – 2017", "eCommerce"]}
						markdown={<RbppContent />}
						logo={rblogo}
						isMaximized={location.pathname === "/rb-product-page/"}
						to="/rb-product-page"
						customTheme="rbpp"
					/>
				</div>
				<div className="blockConnectorContainer">
					<div className="blockConnector">
						<div className="blockConnectorIndicator">
							<div className="blockConnectorIcon">
								<Rocket size={32} />
							</div>
						</div>
					</div>
					<div className="blockConnectorDescription">
						<Heading>THE HORIZON</Heading>
						<div className="blockConnectorCaption">
							<GradientText>
								Recently, my partner and I relocated to Santa Cruz, California,
								and I am eagerly seeking a fulfilling position where I can make
								a meaningful impact
							</GradientText>
						</div>
						<p className="blockConnectorSubCaption">
							With experience spanning multiple disciplines, industries, and
							organizations, I'm confident in my ability to excel in any role as
							long as the product is both captivating and meaningful.
						</p>
						<p className="blockConnectorSubCaption">
							While I possess expertise in senior IC design roles, I am also
							eager to venture into UX Engineering, where I can challenge myself
							and utilize my entire range of technical abilities.
						</p>
						<p className="blockConnectorSubCaption">
							Feel free to reach out for a chat!
						</p>
					</div>
					<div className="introBlock">
						<div className="introCorner" />
						<div className="introHorizontal" />
						<div className="intro">
							<div>
								<List>
									<ListItem indicator={emailHover ? <Copy /> : <Mail />}>
										<CopyToClipboard
											text="hello@marcus.design"
											className="emailAction"
											role="button"
											tabIndex="0"
											onCopy={() => setCopiedEmail(true)}
											onMouseOver={() => setEmailHover(true)}
											onFocus={() => setEmailHover(true)}
											onBlur={() => setEmailHover(false)}
											onMouseLeave={() => setEmailHover(false)}
										>
											<button className="emailAddress">
												{copiedEmail
													? "Copied to clipboard, talk soon!"
													: "hello@marcus.design"}
											</button>
										</CopyToClipboard>
									</ListItem>
									<ListItem
										indicator={linkedInHover ? <ExternalLink /> : <Linkedin />}
									>
										<a
											href="https://www.linkedin.com/in/marcushammarstedt/"
											target="_blank"
											rel="noreferrer"
											onMouseOver={() => setLinkedInHover(true)}
											onFocus={() => setLinkedInHover(true)}
											onBlur={() => setLinkedInHover(false)}
											onMouseLeave={() => setLinkedInHover(false)}
										>
											LinkedIn
										</a>
									</ListItem>
									<ListItem
										indicator={gitHubHover ? <ExternalLink /> : <Github />}
									>
										<a
											href="https://github.com/marchamm"
											target="_blank"
											rel="noreferrer"
											onMouseOver={() => setGitHubHover(true)}
											onFocus={() => setGitHubHover(true)}
											onBlur={() => setGitHubHover(false)}
											onMouseLeave={() => setGitHubHover(false)}
										>
											GitHub
										</a>
									</ListItem>
								</List>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="block playgroundsContainer">
						<Heading highlight>PLAYGROUNDS</Heading>
						<div className="playSpots">
							<SpotExternal
								title="Burst plugin"
								description="I developed this plugin as a fun little side project for a previous portfolio. It simply takes any element, clones and animates it in fun and different ways on any device."
								labels={["javascript"]}
								link="https://github.com/marchamm/burst-animation"
							/>
							<SpotExternal
								title="Configuration"
								description="As part of a redesign of an e-commerce product page I was exploring different configuration patterns. This prototype is an example of how I approached it."
								labels={["HTML", "CSS", "JAVASCRIPT"]}
								link="https://cdpn.io/marchamm/debug/pVdzGY"
							/>
							<SpotExternal
								title="Design at scale"
								description="When designing for multiple languages, themes and devices I find that it helps to have prototypes that are configurable to ensure that we handle different scenarios gracefully."
								labels={["HTML", "CSS", "REACT"]}
								link="http://pp-config.branch.redbubble.design/"
							/>
						</div>
					</div> */}
				<div className="block" />
			</div>
			<AnimatePresence initial={false}>{children}</AnimatePresence>
		</main>
	);
};

export default Layout;
