import React from "react";
import "./list.scss";

const List = ({ children }) => {
	return <ul className="list">{children}</ul>;
};

export const ListItem = ({ indicator, children, link, linkTitle }) => {
	return (
		<li className="listItem">
			<div className="listIndicator text">{indicator}</div>
			<div className="listItemContent">
				<div className="listItemBody text">{children}</div>
				{link && (
					<a
						className="listItemLink text textLink"
						href={link}
						target="_blank"
						rel="noreferrer"
					>
						{linkTitle}
					</a>
				)}
			</div>
		</li>
	);
};

export const ListSeparator = () => <span className="listSeparator">•</span>;

export default List;
